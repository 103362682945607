// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { token } from '@atlaskit/tokens';
import { B400, R400, N20 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LoadingSkeleton = styled.div({
	backgroundColor: token('elevation.surface', `${N20}`),
	height: '16px',
	width: '128px',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextLabelWrapper = styled.span({
	color: token('color.text.accent.red', `${R400}`),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextCorrectionWrapper = styled.a({
	cursor: 'pointer',
	color: token('color.text.accent.blue', `${B400}`),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AutocorrectWrapper = styled.div({
	textTransform: 'initial',
	fontWeight: 'initial',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NoResultsAutocorrectWrapper = styled.div({
	marginTop: token('space.100', '8px'),
	font: token('font.body.UNSAFE_small'),
	marginLeft: token('space.200', '16px'),
});
