import { useCallback } from 'react';

import { ConfluenceSiteAri } from '@atlassian/ari/confluence/site';
import { AIAnswerAPIType } from '@atlassian/search-ai';
import { sainStreamFetchHandlerAssistanceService } from '@atlassian/search-ai/stream-fetch';
import type { AIAnswerQueryFunctionType, SearchAIAnswerQueryVariables } from '@atlassian/search-ai';

import { useSessionData } from '@confluence/session-data';

export const AI_UPSELL_NOTIFICATION_FEATURE_FLAG = 'platform.ai.enable-ai-upsell';

const SAIN_ASSISTANCE_SERVICE_FETCH_TIMEOUT = 20000;

export type useAISearchSupplierArgs = {
	prequeryBannerEnabled?: boolean;
	dismissPrequeryBanner?: () => void;
};

export type AISearchConfigType =
	| {
			aiSearchQueryFunction: AIAnswerQueryFunctionType;
			prequeryBannerEnabled?: boolean;
			dismissPrequeryBanner?: () => void;
			followUpsEnabled?: boolean;
	  }
	| undefined;

type AISearchSupplierType = {
	aiSearchConfig: AISearchConfigType;
};

export const useAISearchSupplier = ({
	prequeryBannerEnabled,
	dismissPrequeryBanner,
}: useAISearchSupplierArgs): AISearchSupplierType => {
	const { cloudId, isAdminHubAIEnabled } = useSessionData();

	const cloudIdARI = ConfluenceSiteAri.create({
		siteId: cloudId,
	}).toString();

	const aiSearchStreamingFunction = useCallback(
		({ variables }: { variables: Omit<SearchAIAnswerQueryVariables, 'cloudIdARI'> }) => ({
			type: AIAnswerAPIType.STREAM,
			stream: sainStreamFetchHandlerAssistanceService({
				variables: { ...variables, cloudIdARI },
				isReadingAids: false,
				fetchConfig: { timeout: SAIN_ASSISTANCE_SERVICE_FETCH_TIMEOUT },
			}),
		}),
		[cloudIdARI],
	);

	return {
		aiSearchConfig: isAdminHubAIEnabled
			? {
					aiSearchQueryFunction: aiSearchStreamingFunction,
					prequeryBannerEnabled,
					dismissPrequeryBanner,
					followUpsEnabled: true,
				}
			: undefined,
	};
};
